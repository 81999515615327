<template>
  <g
    class="Trigger"
  >
    <polygon
      v-if="area"
      :points="createPolygon(area)"
      :stroke-width="`${ 3 * zoomFactor }px`"
      class="drawArea"
      :stroke="color"
      stroke-linejoin="round"
    />
    <template v-if="edit">
      <circle
        v-for="(point, index) in area"
        ref="pointHandle"
        :key="`point-${ index }`"
        :r="2 * zoomFactor"
        :cx="point.pan"
        :cy="-point.tilt"
        :stroke-width="2 * zoomFactor"
        :data-index="index"
        :index="index"
        class="point-handle cursor-move"
        fill="white"
        fill-opacity="0.5"
        stroke="darkgray"
      />
    </template>
  </g>
</template>

<script>
import interact from 'interactjs';

export default {
  name: 'Trigger',
  props: {
    area: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default () {
        return "rgba(216, 161, 9, 0.945)";
      }
    },
    scale: {
      type: Object,
      required: false,
      default: () => ({
        scaleX: 1,
        scaleY: 1,
      })
    },
    zoomFactor: {
      type: Number,
      required: false,
      default () {
        return 1;
      }
    },
  },
  mounted: function () {
    this.addDraggable();
  },
  beforeDestroy: function () {
    this.removeDraggable();
  },
  methods: {
    update () {
      this.$nextTick(() => {
        this.removeDraggable();
        this.addDraggable();
      });
    },
    createPolygon (positions) {
      if (!positions.length) {
        return '';
      }
      
      if (typeof positions[0].pan === 'undefined') {
        return '';
      }
      let result = ``;
      for (let index = 0; index < positions.length; index++) {
        result = `${ result } ${ positions[index].pan },${ -positions[index].tilt }`;
      }
      return result;
    },
    round (number) {
      //Round values to 2 decimal digits
      return Math.round(number * 1e2) / 1e2;
    },
    removeDraggable () {
      if (!this.edit) {
        return;
      }

      if (this.$refs.pointHandle) {
        this.$refs.pointHandle.forEach(o => {
          interact(o).unset();
        });
      }
    },
    addDraggable () {
      if (!this.edit) {
        return;
      }

      this.$refs.pointHandle.forEach(o => {
        interact(o)
        .draggable({ max: Infinity, restrict: { elementRect: { top: 0, left: 0, bottom: 1, right: 1 } },
          onmove: (event) => {
            let index = event.target.attributes.index.value;
            this.area[index].pan += event.dx * this.scale.scaleX;
            this.area[index].tilt -= event.dy * this.scale.scaleY;

            if(this.round(this.area[index].pan) > 170) {
              this.area[index].pan = 170;
            }
            else if(this.round(this.area[index].pan) < -170) {
              this.area[index].pan = -170;
            }
            else {
              this.area[index].pan = this.round(this.area[index].pan);
            }

            if(this.round(this.area[index].tilt) > 80) {
              this.area[index].tilt = 80;
            }
            else if(this.round(this.area[index].tilt) < -80) {
              this.area[index].tilt = -80;
            }
            else {
              this.area[index].tilt = this.round(this.area[index].tilt);
            }
            this.$emit('changePtz', this.area[index]);
          }
        });
      });
    },
  }
}
</script>

<style scoped>
g {
  opacity: 0.8;
}
g circle {
  opacity: 0.7;
}
.drawArea {
  fill: none;
}
</style>
